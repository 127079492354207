.bg-postdetails {
  padding: 76px 0 120px;
  margin: 20px auto;
  background-size: cover;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  position: relative;
  background-position: center center;
}

.postdetails-item {
  display: flex;
  align-items: center;
  padding: 0.5em 0;
  font-size: 20px;
  overflow: hidden;
  border-radius: 0.3rem;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: top left;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.postdetails-item:hover {
  box-shadow: none;
  cursor: auto;
}

.postdetails-item .postdetails-header {
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.postdetails-item .postdetails-header .left {
  display: flex;
  margin: 0 30px 0 0;
}

.postdetails-item .postdetails-header .right {
  margin: 4px 0 0;
  display: flex;
}

.postdetails-item .postdetails-header .feedback-date {
  opacity: 1;
  transition: opacity 0.4s ease;
}

.postdetails-item .postdetails-header .date-published {
  color: rgba(71, 82, 93, 0.4);
  font-size: 1rem;
  display: block;
  white-space: nowrap;
}

.postdetails-item .postdetails-details {
  justify-content: flex-start;
  flex-wrap: wrap;
  line-height: 1.3;
  margin-left: 0;
  margin-right: 0;
  text-decoration: none;
  overflow: hidden;
  flex-direction: column;
  flex-grow: 1;
  cursor: text;
}
.postdetails-item .postdetails-details .postdetails-title {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  margin-right: 0.5em;
  margin-left: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  flex-shrink: 0;
}

@media (min-width: 850px) {
  .bg-postdetails {
    width: 750px;
  }
  .postdetails-item .postdetails-details .postdetails-title {
    margin-left: 0;
  }
}

.postdetails-item .postdetails-details .postdetails-description {
  padding: 0;
  margin: 0;
  display: block;
  text-overflow: ellipsis;
  font-size: 16px;
  overflow: hidden;
  color: #212529;
  text-decoration: none;
}

.postdetails-item .postdetails-details .postdetails-description img {
  width: 70%;
}

.postdetails-item .user {
  display: flex;
}

.postdetails-item .user .profile-pic {
  height: 35px;
  width: 35px;
  border-radius: 1em;
  background-color: gray;
  margin: 4px 0 4px;
  background-size: 100% 100%;
}

.postdetails-item .user .user-name {
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  margin-left: 0.5rem;
}

.postdetails-item .user .user-product {
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  margin-left: 0.5rem;
}

.postdetails-upvote {
  width: 60px;
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.postdetails-upvote .upvote-count {
  background-color: #66b0ff;
  color: #fff;
  border-radius: 50%;
  padding: 7px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.postdetails-upvote .upvote-count:hover {
  background-color: #4e9aeb;
  color: #f6f6f6;
}

.statusStale {
  display: flex;
  align-items: center;
  margin: 4px 0 0;
}

.postStatus.pending {
  color: #85b5b5;
}
.postStatus.planned {
  color: #4e5aff;
}
.postStatus.ongoing {
  color: #65c275;
}
.postStatus.completed {
  color: #4e9aeb;
}
.postStatus.not-an-issue {
  color: #9e5a5a;
}
.postStatus {
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}
.uppercaseHeader {
  color: #999;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 17px;
  text-transform: uppercase;
}

.board-btn {
  background-color: #e4e6e7;
  border: none;
  color: #4d4d4d;
  font-weight: 400;
  font-size: 10px;
}

.ui.comments {
  margin: 1.5em 0;
  max-width: none !important;
}

.ui.comments .comment .avatar img,
.ui.comments .comment img.avatar {
  border-radius: 45% !important;
}

.ui.comments .comment .author {
  text-decoration: none;
}

.update-status {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.75em 0 0;
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  font-weight: 700;
  margin-left: 1em;
}

i.icon.check.circle:before {
  color: #ffce48;
}

.post-sub-title {
  font-size: 14px;
  font-weight: 600;
  color: #999;
}

.modify-post {
  align-items: left;
}

.edit-post {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.75em 0 0;
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  font-weight: 700;
}

.delete-post {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.75em 0 0;
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  font-weight: 700;
  margin-left: 1em;
}

.ui.comments .comment .text img {
  width: auto;
  max-height: 500px;
}

.ui.comments .comment .text {
  font-size: 16px;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote p {
  display: inline;
}

.markdown-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.markdown-section .left {
  display: flex;
  margin: 0 30px 0 0;
}

.markdown-section .right {
  margin: 4px 0 0;
  display: flex;
}

@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.board-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  .nav {
    width: 100%;
    height: 80px;
    background-color: #fff;
  }
  .board {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    .top-wrap {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      margin-top: 20px;
      margin-bottom: 20px;
      .row {
        flex: 1;
        @media (max-width: 780px) {
          flex-direction: column;
          justify-content: flex-start;
        }
      }
    }
    .card-header {
      background-color: #f8f9fa;
      border: none;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0px;
      right: 0px;
      padding-right: 0px;
      padding-bottom: 0;
      z-index: 1;
      height: 75px;
    }
    .card-title {
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .card-title .left {
      font-size: 18px !important;
      font-weight: 700;
      display: flex;
    }

    .card-title .right {
      margin-right: 10px;
      display: flex;
    }

    .card-title .right .btn {
      color: rgba(55, 53, 47, 0.4) !important;
    }
    .card-title .right .btn:hover {
      background-color: rgba(55, 53, 47, 0.08) !important;
    }
    .post-title {
      margin-bottom: 0;
      font-size: 16px;
    }
    .circle {
      svg {
        width: 15px;
      }
    }
    .left {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    .right {
      width: 100px;
      display: flex;
      justify-content: flex-end;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .count {
        background-color: #66b0ff;
        color: #fff;
        border-radius: 50%;
        padding: 7px;
        font-size: 14px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
      }
    }
    .board-btn {
      background-color: #e4e6e7;
      border: none;
      color: #4d4d4d;
      font-weight: 600;
      font-size: 10px;
    }
    .board-column {
      position: relative;
      width: 32%;
      min-height: 500px;
      overflow: hidden;
      box-sizing: border-box;
      margin-bottom: 30px;
      flex: 1;
      @media (max-width: 780px) {
        width: 100%;
        min-height: 0;
        max-height: 450px;
      }
    }
    .board-card {
      border: none;
      position: absolute;
      max-height: 100%;
      overflow-y: auto;
      width: 100%;
      .card-body {
        cursor: pointer;
        min-height: auto;
      }
      .card-body:hover {
        box-shadow: 0 12px 34px 0 hsla(206, 57%, 12%, 0.1);
      }
      padding-top: 75px;
      &::-webkit-scrollbar {
        width: 0;
      }
      @media (max-width: 780px) {
        position: relative;
        max-height: 430px;
      }
    }
    .board-item {
      border: none;
    }
    .bottom-wrap {
      background-color: #fff;
      height: 60px;
    }
  }
}

.footer-attribution {
  color: "inherit";
  text-decoration: "none";
  color: #888 !important;
}

.footer-attribution:hover {
  color: rgb(99, 99, 99) !important;
}

.title-underline {
  border: 1px solid;
  width: 30px;
  margin-left: 0;
}

.post-item {
  display: flex;
  align-items: center;
  padding: 0.5em;
  font-size: 20px;
  overflow: hidden;
  border-radius: 0.3rem;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: top left;
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}
.post-item:hover {
  box-shadow: 0 12px 54px 0 hsla(206, 57%, 12%, 0.1);
}

.post-item .post-details {
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 0.8em;
  margin-right: 0.5em;
  line-height: 1.3;
  text-decoration: none;
  overflow: hidden;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer;
}
.post-item .post-details .post-title {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  margin-right: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  flex-shrink: 0;
}

.post-item .post-details .post-description {
  padding: 0;
  margin: 0;
  display: block;
  text-overflow: ellipsis;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  color: #212529;
  text-decoration: none;
}

.post-item .user {
  display: flex;
}

.post-item .user .profile-pic {
  height: 35px;
  width: 35px;
  border-radius: 2em;
  background-color: gray;
  margin: 4px 0 4px;
  background-size: 100% 100%;
}

.post-item .user .user-name {
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  margin-left: 0.5rem;
}

.post-upvote {
  width: 45px;
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.post-upvote .upvote-count {
  background-color: #66b0ff;
  color: #fff;
  border-radius: 50%;
  padding: 7px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.post-upvote .upvote-count:hover {
  background-color: #4e9aeb;
  color: #f6f6f6;
}

.statusStale {
  display: flex;
  align-items: center;
  margin: 4px 0 0;
}

.postStatus.pending {
  color: #85b5b5;
}
.postStatus.planned {
  color: #4e5aff;
}
.postStatus.ongoing {
  color: #65c275;
}
.postStatus.completed {
  color: #4e9aeb;
}
.postStatus.not-an-issue {
  color: #9e5a5a;
}
.postStatus {
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}
.uppercaseHeader {
  color: #999;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 17px;
  text-transform: uppercase;
}

.board-btn {
  background-color: #e4e6e7;
  border: none;
  color: #4d4d4d;
  font-weight: 400;
  font-size: 10px;
}

//if you need override variables
$success: #11c63c;
$body-color: #47525d;

@import "~bootstrap/scss/bootstrap";

.changelog-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  padding: 76px 0 120px;
}

//header
.header {
  top: 0;
  left: auto;
  right: 0;
  position: sticky;
  height: 70px;
  text-align: center;
  background: rgba(255, 255, 255, 0.95);
  z-index: 100;
  box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12),
    0 7px 10px -5px rgba(0, 0, 0, 0.15);
  padding: 10px;
}

//content
.article {
  font-family: Helvetica;
  position: relative;
  padding: 25px 0;
  border-bottom: 1px solid #f3f3f3;
  width: 100%;
  margin: auto;
  @include media-breakpoint-up(sm) {
    width: 560px;
  }
  .log-title {
    font-size: 1.5rem;
    color: #333333;
    cursor: pointer;
    font-weight: 600;
  }
  .log-data {
    position: absolute;
    top: 30px;
    left: -120px;
    opacity: 1;
    width: 120px;
    line-height: 1.45;
    transition: opacity 0.4s ease;
    @media only screen and (max-width: 900px) {
      opacity: 0;
    }
  }
  .article-meta {
    margin: 10px 0 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .tag {
      border-radius: 2rem;
      font-size: 12px;
      font-weight: 700;
      padding: 1px 12px;
      height: 20px;
      color: white;
    }
    .article-date {
      opacity: 1;
      transition: opacity 0.4s ease;
      @media (min-width: 900px) {
        opacity: 0;
      }
    }
  }
  .date-published {
    color: rgba(71, 82, 93, 0.4);
    font-size: 1rem;
    display: block;
    white-space: nowrap;
  }
  .article-content {
    p {
      font-size: 1.1rem;
      line-height: 1.7rem;
      white-space: break-spaces;
    }

    img {
      width: 100%;
      max-height: 400px;
    }

    color: #47525d;
  }
}
//arrow back
.back {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  width: 100%;
  // font-size: 1.5rem;
  font-weight: 600;
  color: #47525d;
  cursor: pointer;
  padding: 2rem 0;
  border-bottom: 1px solid #f3f3f3;
  width: 100%;
  margin: auto;
  @include media-breakpoint-up(sm) {
    width: 560px;
  }
  &:focus {
    outline: transparent;
  }
}

.back:hover {
  color: #000;
}

//arrow back
.new-changelog-btn {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  color: #47525d;
  cursor: pointer;
  padding: 2rem 0;
  border-bottom: 1px solid #f3f3f3;
  width: 100%;
  margin: auto;
  @include media-breakpoint-up(sm) {
    width: 560px;
  }
  &:focus {
    outline: transparent;
  }
}

.new-changelog-btn:hover {
  color: #000;
}

.titleDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.titleDiv .left {
  display: flex;
  margin: 0 30px 0 0;
}

.titleDiv .right {
  margin: 4px 0 0;
  display: flex;
}

.social-share-btn {
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
}

.modify-changelog {
  align-items: left;
}

.edit-changelog {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.75em 0 0;
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  font-weight: 700;
}

.delete-changelog {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.75em 0 0;
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  font-weight: 700;
  margin-left: 1em;
}

.bg-post {
  padding: 76px 0 120px;
  background-size: cover;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  position: relative;
  background-position: center center;
}

.btn-action {
  border: 2px solid transparent;
  width: 16rem;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.btn-action:hover {
  box-shadow: 0 12px 54px 0 hsla(206, 57%, 12%, 0.1);
  border: 1px solid hsla(206, 57%, 12%, 0.1);
}

.post-action-list {
  display: flex;
  justify-content: space-around;
  margin: 20px auto;
}

@media (min-width: 850px) {
  .post-action-list {
    width: 750px;
  }
}

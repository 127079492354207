.settings-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  padding: 76px 0 120px;
}

@media (max-width: 767px) {
  .headerSpace.container {
    min-height: 90px !important;
  }
}

.company-item {
  display: flex;
  outline: none !important;
  box-shadow: none;
}

img.company-logo {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: gray;
  margin: 4px 0 4px;
  background-size: 100% 100%;
}

.company-name {
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  margin-left: 0.5rem;
}

.btn.btn-primary.btn-new-company {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
}

.list-group-item:first-child {
  border: none;
}

.list-group-item + .list-group-item {
  border: none;
}

.list-group-item:last-child {
  border: none;
}

.list-group-item-light.list-group-item-action.active {
  color: #8c8c8d !important;
  background-color: #ececf6 !important;
  border-color: #ececf6 !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}
